import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        activePackage: null,
        activeAdvice: false,
        toolAdvicePosition: 0
    },
    getters: {
        ACTIVE_PACKAGE: state => state.activePackage,
        ACTIVE_ADVICE: state => state.activeAdvice,
        ADVICE_POSITION: state => state.toolAdvicePosition
    },
    mutations: {
        setActivePackage: (state, packageItem) => state.activePackage = packageItem,
        setActiveAdvice: (state, value) => state.activeAdvice = value,
        setToolPosition: (state, value) => state.toolAdvicePosition = value
    }
})

export default store;