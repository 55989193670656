<template>
  <div class="container">
    <div class="content__wrap">
      <div class="top-block">Slimme Wijken Rekenhulp</div>
      <div class="content">
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </div>
      <div class="bottom-block">
        <a href="https://www.mijnslimmewijk.nl/Cookieverklaring"
          >Cookieverklaring</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content__wrap {
  max-width: var(--container-width);
}

.content {
  padding: 24px 0;
}

.top-block {
  background-color: var(--primary-color);
  padding: 8px;
  text-align: center;
  color: white;
  font-family: var(--font-family-heading);
}

.bottom-block {
  background-color: var(--primary-color);
  height: 35px;
  text-align: right;
  font-size: 13px;
  font-family: var(--font-family-text);
  line-height: 35px;
  padding: 0 8px;
  color: white;
}
.bottom-block a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}
.bottom-block a:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 1024px) {
  .content {
    padding: 24px 16px;
  }
}
</style>
