<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
const master_layout = "master";
export default {
  computed: {
    layout() {
      return `${master_layout}-layout`;
    },
  },
};
</script>

<style lang="scss"></style>
