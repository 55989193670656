import Vue from "vue";
import App from "./App.vue";
import Master from "./layouts/Master.vue";
import router from "./router";
import store from "./store/state";

// Global css
import "./assets/_global.scss";

// Layouts
Vue.component("master-layout", Master);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");