import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "keuzemenu",
    component: () => import("../views/KeuzeMenu.vue"),
  },
  {
    path: "/rekenmodule",
    name: "rekenmodule",
    component: () => import("../views/RekenModule.vue"),
  },
  {
    path: '*',
    component: () => import("../views/NotFound.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;